import { type ToastContainerProps } from 'react-toastify';

import { type InboundStatus } from '../context/InboundDataContext';
import { type InboundActionComingFromTheInboundListing } from '../context/NewInboundDataContext';

export const FIVE_MINUTES = 1000 * 60 * 5;
export const ONE_HOUR = 1000 * 60 * 60 * 1;
export const SIBLINGS_PAGES_SEPARATOR = 1;

export const TOAST_DEFAULT_PROPS: Partial<ToastContainerProps> = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  newestOnTop: true,
  closeOnClick: true,
  pauseOnHover: true,
  pauseOnFocusLoss: true,
  draggable: true,
};

export const DD_MM_AAAA_FORMAT_OPTION: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
};

export const DD_OF_MM_OF_YYY_FORMAT_OPTION: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
};

export const REGULAR_EXPRESSIONS = {
  hyphen: /-/g,
  comma: /,/g,
  dot: /\./g,
  realPrefix: /R\$/g,
} as const;

export const INBOUND_STATUS_TRANSLATIONS: {
  [key in InboundStatus]: string;
} = {
  draft: 'Rascunho',
  sent: 'Enviado',
  received: 'Recebido',
  processing: 'Processando',
  stored: 'Armazenado',
  deleted: 'Deletado',
} as const;

export const INBOUND_STATUS_TITLE_TRANSLATIONS: {
  [key in InboundStatus | 'all']: string;
} = {
  all: 'Todos envios',
  ...INBOUND_STATUS_TRANSLATIONS,
} as const;

export const INBOUND_ACTION_BUTTON_TRANSLATIONS: {
  [key in InboundActionComingFromTheInboundListing]?: string;
} = {
  continue: 'Continuar',
  review: 'Revisar',
  'send-invoice': 'Enviar NF',
} as const;

export const INPUT_ACCEPT = {
  xml: '.xml, text/xml',
  csv: '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
} as const;
