import { lazy } from 'react';

export const InboundHome = lazy(() =>
  import('../pages/InboundHome').then(module => ({
    default: module.InboundHome,
  }))
);

export const NewInbound = lazy(() =>
  import('../pages/NewInbound').then(module => ({
    default: module.NewInbound,
  }))
);
