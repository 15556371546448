import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import {
  ProfileDataContextProvider,
  type ProfileData,
} from './context/ProfileDataContext';
import { queryClient } from './lib/queryClient';
import { Routes } from './routes';
import { TOAST_DEFAULT_PROPS } from './utils/constants';

export interface InboundAppProps {
  profileData: ProfileData | null;
}

export const InboundApp = ({ profileData }: InboundAppProps) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools buttonPosition="bottom-left" />

      <ProfileDataContextProvider profileData={profileData}>
        <ToastContainer {...TOAST_DEFAULT_PROPS} />
        <Routes />
      </ProfileDataContextProvider>
    </QueryClientProvider>
  );
};
