import { PageLoading } from '@Estoca/ui';
import { Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { InboundHome, NewInbound } from './lazyRoutes';

export const Routes = () => (
  <Suspense fallback={<PageLoading />}>
    <BrowserRouter>
      <Switch>
        <Route exact path="/inbound" component={InboundHome} />
        <Route
          exact
          path={[
            '/inbound/new',
            '/inbound/:inboundId/review',
            '/inbound/:inboundId/continue',
            '/inbound/:inboundId/send-invoice',
          ]}
          component={NewInbound}
        />
      </Switch>
    </BrowserRouter>
  </Suspense>
);
